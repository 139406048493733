import React from 'react';
import { Link } from 'react-router-dom';
import './loginPage.css';
import logo from './bil.png'; // Sørg for at denne stien er korrekt
import fotballspiller from './fotballspiller.png'; // Sørg for at denne stien er korrekt

const LoginPage = () => {
  return (
    <div className="login-page">
              <meta name="theme-color" content="#fff" />
      
      <img src={logo} alt="Logo" className="login-logo" />
      <div className="login-banner">
        
        <img src={fotballspiller} alt="Fotballspiller" className="login-player-image" />
      </div>
      <div className="login-container">

        <h1 className="login-header">Velkommen til MittBIL Billettportal</h1>
        <p className="login-description">
          Bli en del av opplevelsen nå og sikre din plass i morgendagens spill.
        </p>
        <Link to="/login" className="action-button">Logg Inn</Link>
        <Link to="/register" className="action-button">Registrer deg</Link>

      </div>
      <p className="login-description1">
          Utviklet av Espen Halsen
        </p>
    </div>
  );
};

export default LoginPage;
