import { getFirestore, doc, getDoc } from 'firebase/firestore';

const useCheckVersionAndUpdate = async () => {

  const db = getFirestore();
  const versionControlDocRef = doc(db, 'settings', 'versioncontrol');
  const versionControlDocSnap = await getDoc(versionControlDocRef);

  if (versionControlDocSnap.exists()) {
    const latestVersion = versionControlDocSnap.data().version;
    const currentVersion = '5.1.2'; // App's hardcoded version

    if (latestVersion !== currentVersion) {
      console.log('New version available. Updating the app...');

      // Clear cookies
      document.cookie.split(';').forEach((c) => {
        document.cookie = c.trim().split('=')[0] + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
      });

      // Clear localStorage and sessionStorage
      localStorage.clear();
      sessionStorage.clear();

      // Clear caches
      if ('caches' in window) {
        caches.keys().then((keyList) => {
          Promise.all(keyList.map((key) => caches.delete(key)));
        });
      }

      // Reload the page from the network
      window.location.reload(true);
    }

  } else {
    console.log('Could not find the version control document.');
    window.location.href('/loginpage');
  }
};

export default useCheckVersionAndUpdate;