import React, { useState } from 'react';
import { getAuth,  updatePassword, deleteUser } from 'firebase/auth';
import './myProfile.css';
import Navbar from '../../components/common/navbar/Navbar';
import BottomBar from '../../components/common/navbar/bottombar';

const MyProfile = () => {
  const [newPassword, setNewPassword] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;



  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!newPassword) return;
    if (window.confirm('Er du sikker på at du vil endre passordet ditt?')) {
      try {
        await updatePassword(user, newPassword);
        alert('Passordet ditt er oppdatert.');
      } catch (error) {
        alert('Kunne ikke oppdatere passordet. Feil: ' + error.message);
      }
    }
  };

  const handleDeleteUser = async () => {
    if (window.confirm('Er du sikker på at du vil slette kontoen din permanent? Du vil miste dine billetter og kjøpshistorikk. Denne handlingen kan ikke angres.')) {
      try {
        await deleteUser(user);
        alert('Kontoen din er slettet.');
        // Implementer logikk for å omdirigere brukeren etter sletting
      } catch (error) {
        alert('Kunne ikke slette kontoen. Feil: ' + error.message);
      }
    }
  };

  return (
    <div className="myProfile">
      <Navbar />
      <div className="profile">
      <div className="profile-container">
        <div className="profile-header">
        <h1>Min Profil</h1>
        <h4>Her kan du finne info, endre passord og slette kontoen din.</h4>
        <div className="user-info">
          <p><strong>Din e-post:</strong> {user?.email}</p>
          <p><strong>Din bruker-ID:</strong> {user?.uid}</p>
        </div>
        <form onSubmit={handlePasswordChange} className="profile-form">
          <label>
            <h3>Endre ditt passord:</h3>
            Nytt passord:
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </label>
          <button type="submit">Endre Passord</button>
        </form>
        <h3>Slett din konto:</h3>
        <button onClick={handleDeleteUser} className="delete-btn">Slett Konto</button>
      </div>
        </div>
        </div>
      <BottomBar />
    </div>
  );
};

export default MyProfile;
