// src/firebase/config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"; // Make sure to import getFunctions
const firebaseConfig = {

    apiKey: "AIzaSyC6dDzr75cu5sb2wWkFnVsgfYj-rAIRNHU",
  
    authDomain: "goalpass-mgmt.firebaseapp.com",
  
    projectId: "goalpass-mgmt",
  
    storageBucket: "goalpass-mgmt.appspot.com",
  
    messagingSenderId: "704537570508",
  
    appId: "1:704537570508:web:cd56d13ea8493354e32669",
  
    measurementId: "G-T5B7JQ5M8Y"
  
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app); // Initialize functions

export const db = getFirestore(app);

export {  auth, functions }; // Ensure you're exporting functions here