import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import Login from './pages/LoginPage/Login';
import Register from './pages/LoginPage/Register';
import ForgotPassword from './pages/LoginPage/ForgotPassword';
import Dashboard from './pages/Dashboard/Dashboard';
import TicketPurchase from './pages/Tickets/TicketPurchase';
import MyPurchases from './pages/MyPurchases/MyPurchases';
import MyProfile from './pages/MyProfile/MyProfile';
import useAuth from './hooks/useAuth';
import useCheckVersionAndUpdate from './utils/version';
import ChangeOwner from './pages/Dashboard/changeOwner';
import Map from './pages/Dashboard/map';


const App = () => {
  useCheckVersionAndUpdate(); // Check the version and update the app if necessary
  const { currentUser } = useAuth(); // Assuming this hook returns the user object if logged in, otherwise null



  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/loginpage" element={currentUser ? <Navigate to="/dashboard" /> : <LoginPage />} />
        <Route path="/login" element={currentUser ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/register" element={currentUser ? <Navigate to="/dashboard" /> : <Register />} />
        <Route path="/forgot-password" element={currentUser ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
        <Route path="/dashboard" element={currentUser ? <Dashboard /> : <Navigate to="/loginpage" />} />
        <Route path="/purchase" element={currentUser ? <TicketPurchase /> : <Navigate to="/loginpage" />} />
        <Route path="/mypurchases" element={currentUser ? <MyPurchases /> : <Navigate to="/loginpage" />} />
        <Route path="/myprofile" element={currentUser ? <MyProfile /> : <Navigate to="/loginpage" />} />
        <Route path="/changeowner/:ticketId" element={currentUser ? <ChangeOwner /> : <Navigate to="/loginpage" />} />
        <Route path="/map" element={currentUser ? <Map /> : <Navigate to="/loginpage" />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
