import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { v4 as uuidv4 } from 'uuid';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './TicketPurchase.css';
import Navbar from '../../components/common/navbar/Navbar';
import BottomBar from '../../components/common/navbar/bottombar';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './Modal.css';
import './ModalSuccess.css';
import bilvsjunk from './bilvsjunk.png'


const stripePromise = loadStripe('pk_live_51P0ZwqLQ0gzNYuYV1kdyfOr0OKM4n7Qok6jvRY1ByXfM3NuoJlJaT4fJMNKA46JomYt6XXZP2nxfu23CR554paJD00m6JrYL1Q');

const TicketPurchaseForm = ({ user, ticketPrice, tickets, setTickets, section, setSection, ticketTypes, setTicketTypes }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const functions = getFunctions();
  const [showModal, setShowModal] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [processData, setProcessData] = useState(null);
  const [purchaseError, setPurchaseError] = useState(null);


  const calculateTotalSum = (ticketTypes) => {
    let sum = ticketTypes.reduce((acc, type) => acc + (type === 'child' ? ticketPrice * 0.5 : ticketPrice), 0);

    return sum;
  };

  let totalSum = calculateTotalSum(ticketTypes);

  const handlePurchase = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe has not loaded yet.");
      return;
    }

    if (user) {
      setShowContent(false);
      setShowModal(true);
    } else {
      navigate('/login');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShowContent(true);
  };
  const PurchaseSuccessDialog = () => {
    return (
      <div className="modal1">
        <div className="modal-content">

          <div className="purchase1-success">
            {processData?.success && (
              <>
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <h2>Kjøpet er gjennomført!</h2>
                <p>Dine billetter ligger nå trygt på hovedsiden (hvor du for øvrig alltid finner dem).</p>
                <button onClick={() => navigate('/dashboard')} className="my-tickets-button">Mine billetter</button>
              </>)}
            {(!processData?.success && !purchaseError) && (
              <>

                <h2>Kjøpet gjennomføres...</h2>
                <div className="loader"></div>
              </>)}
            {purchaseError && (
              <>
                <div className="error-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12" y2="16"></line>
                  </svg>
                </div>
                <h2>Kjøpet feilet</h2>
                <p>{purchaseError}. Skulle dette vedvare, ta kontakt på post.bilfotball@gmail.com</p>
                <button onClick={() => navigate('/dashboard')} className="my-tickets-button">Tilbake</button>
              </>)}
          </div>
        </div>
      </div>
    );
  };

  const confirmPurchase = async () => {
    setConfirmButtonDisabled(true);
    setShowSuccessDialog(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('Error:', error);
      setPurchaseError(error.message);
      setConfirmButtonDisabled(false);

      return;
    }

    const processPayment = httpsCallable(functions, 'processPayment');
    processPayment({
      paymentMethodId: paymentMethod.id,
      amount: totalSum * 100, // Convert to the smallest currency unit
    }).then((result) => {

      if (result?.data?.success) {
        for (let i = 0; i < tickets; i++) {
          addDoc(collection(db, "tickets"), {
            userId: result.data.userId, // Use the user ID returned from the payment function
            section,
            ticketId: uuidv4(),
            confirmed: true,
            purchaseDate: new Date().toISOString(),
            matchDate: "2024-08-24",
            match: "NM-cup: Brønnøysund IL vs Junkeren Elite",
            type: ticketTypes[i],
            paymentIntentId: result.data.paymentIntentId,
          });
        }
        console.log(`${tickets} ticket(s) purchased successfully.`);
        setProcessData(result.data);
        setShowModal(false);
      }

    }).catch((error) => {
      console.log(error);
      setPurchaseError(error.message);
      setConfirmButtonDisabled(false);
    })

  };


  const handleTicketNumberChange = (e) => {
    const numberOfTickets = parseInt(e.target.value);
    setTickets(numberOfTickets);
    setTicketTypes(Array(numberOfTickets).fill('adult'));
  };



  return (
    <div className="ticket-container">

      {showContent && (
        
        <div className="ticket-purchase-container">

          <div className="ticket-purchase-header">
            <h1>NM-cup: Brønnøysund IL vs Junkeren Elite</h1>
            <br />
            <img src={bilvsjunk} alt="logo" className="logo" width="200px"/>
            <p>24. august | 13:00 - Brønnøyhallen</p>
            <p>Pris per billett: <br />{ticketPrice} kr for voksne (16 år og eldre), {ticketPrice * 0.5} kr for barn</p>
          </div>

          <form className="ticket-purchase-form" onSubmit={handlePurchase}>
            <label>
              Antall billetter:
              <select value={tickets} onChange={handleTicketNumberChange}>
                {[...Array(5).keys()].map(n => (
                  <option key={n} value={n + 1}>{n + 1}</option>
                ))}
              </select>
            </label>
            <label>
              Velg felt:
              <select value={section} onChange={(e) => setSection(e.target.value)}>
                <option value="A">Tribune</option>
              </select>
            </label>
            {Array.from({ length: tickets }, (_, i) => (
              <label key={i}>
                Billett {i + 1} type:
                <select value={ticketTypes[i]} onChange={(e) => {
                  const newTicketTypes = [...ticketTypes];
                  newTicketTypes[i] = e.target.value;
                  setTicketTypes(newTicketTypes);
                }}>
                  <option value="adult">Voksen</option>
                  <option value="child">Barn</option>
                </select>
              </label>
            ))}
            <br />
            <div className="total-sum">Totalsum: <strong>{calculateTotalSum(ticketTypes)} kr</strong></div>
            <button type="submit" className="purchase-button">Kjøp</button>

          </form>
        </div>
        
      )}
      {showSuccessDialog && <PurchaseSuccessDialog />}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>🗙</span>
            <h2>Betal ditt kjøp</h2>
            <h4>Kamp: Brønnøysund IL vs Junkeren Elite</h4>
            <p>Antall billetter: <strong>{tickets}</strong></p>
            <p>Felt: <strong>{section}</strong></p>
            <div className="card-details-prompt">
              Her kan du fylle inn kortdetaljer. Vi lagrer <strong>ikke</strong> kortinformasjonen din.
            </div>

            <div className="card-element-container">
              <CardElement options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#fa755a',
                  },
                },
              }} />
            </div>
            <div className="payment-summary">
              <h3>Total sum: {totalSum} kr</h3>

              
              <p>Trykk på <strong>"Bekreft Kjøp"</strong> for å fullføre kjøpet.</p>
            </div>
            <button className="confirm-purchase-button" onClick={confirmPurchase} disabled={confirmButtonDisabled}>Bekreft Kjøp</button>
            <button onClick={() => navigate('/dashboard')} className="back-button">
              Avbryt kjøp
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const TicketPurchase = () => {
  const [tickets, setTickets] = useState(1);
  const [ticketTypes, setTicketTypes] = useState(['adult']);
  const [section, setSection] = useState('A');
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const ticketPrice = 100; // Adjust as necessary


  return (
    <Elements stripe={stripePromise}>
      <div className="container">
        <Navbar />
        <div className="ticket-purchase">
          <TicketPurchaseForm
            user={user}
            ticketPrice={ticketPrice}
            tickets={tickets}
            setTickets={setTickets}
            section={section}
            setSection={setSection}
            ticketTypes={ticketTypes}
            setTicketTypes={setTicketTypes}
          />
        </div>
        <BottomBar />

      </div>
    </Elements>
  );
};

export default TicketPurchase;