import React from 'react';
import './slideMenu.css';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Importert for navigasjon

const SlideMenu = ({ isOpen, toggleMenu }) => {
  const auth = getAuth();
  const navigate = useNavigate(); // Opprettet for navigasjon

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('Brukeren er logget ut');
      navigate('/loginpage'); // Navigerer til loginsiden etter utlogging
      toggleMenu();
    }).catch((error) => {
      console.error('Feil ved utlogging:', error);
    });
  };

  return (
    <div className={`slide-menu ${isOpen ? 'open' : ''}`}>
      <ul>
        <li onClick={() => { navigate('/dashboard'); toggleMenu(); }}>Hjem</li>
        <li onClick={() => { navigate('/myprofile'); toggleMenu(); }}>Min Profil</li>
        <li onClick={() => { navigate('/mypurchases'); toggleMenu(); }}>Kjøpshistorikk</li>
        <li onClick={handleLogout}>Logg ut</li>
      </ul>
      <div className="app-info">
        <p>MittBIL</p>
        <p>&copy; {new Date().getFullYear()} MittBIL. Alle rettigheter reservert.</p>
        <p>Versjon: 5.2</p>
        <br />
        <p>Laget for Brønnøysund Idrettslag</p>
        <p>Utviklet av Espen Halsen</p>
      </div>
    </div>
  );
};

export default SlideMenu;
