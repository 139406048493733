// changeOwner.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore'; // Oppdater importene
import { db } from '../../firebase/config';
import Navbar from '../../components/common/navbar/Navbar';
import BottomBar from '../../components/common/navbar/bottombar';
import './changeOwner.css';
import { doc } from 'firebase/firestore';

const ChangeOwner = () => {
  const [newOwnerId, setNewOwnerId] = useState('');
  const [ticket, setTicket] = useState(null);
  const { ticketId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTicket = async () => {
      const ticketsRef = collection(db, "tickets");
      const q = query(ticketsRef, where("ticketId", "==", ticketId)); // Bruk ticketId for å søke i dokumentene
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Anta at det bare er én billett med denne ticketId
        const docSnap = querySnapshot.docs[0];
        setTicket({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("Ingen billett funnet med det ID!");
      }
    };

    fetchTicket();
  }, [ticketId]);

  const handleChangeOwner = async () => {
    if (ticket && newOwnerId) {
      try {
        const ticketRef = doc(db, "tickets", ticket.id); // Bruk funnet dokument-ID for å oppdatere
        await updateDoc(ticketRef, { userId: newOwnerId });

        alert("Billetteier er endret.");
        navigate('/dashboard');
      } catch (error) {
        console.error("Feil ved endring av billetteier:", error);
        alert("Kunne ikke endre billetteier.");
      }
    } else {
      alert("Vennligst skriv inn et gyldig bruker-ID og sørg for at billetten er gyldig.");
    }
  };

  return (
    <div className="changeOwner">
      <Navbar />
      <div className="changeOwner-container">
        <h2>Endre Billetteier</h2>
        {ticket && (
          <div>
            <h2>Billettinformasjon:</h2>
            <p>Kamp: {ticket.match}</p>
            <p>Type: {ticket.type === 'adult' ? 'Voksenbillett' : 'Barnebillett'}</p>
            <p>Felt: {ticket.section === 'A' ? 'Hjemme' : 'Borte'} </p>
            {/* Pass på at datofeltet eksisterer og er formatert korrekt */}
          </div>
        )}
        <input
          type="text"
          className="new-owner-input"
          value={newOwnerId}
          onChange={(e) => setNewOwnerId(e.target.value)}
          placeholder="Skriv inn ny eiers Bruker-ID"
        />
        <button onClick={handleChangeOwner} className="change-owner-btn">Endre Eier</button>
        <p><strong>OBS! Dobbeltsjekk at Bruker-ID du fyller inn er riktig, og ikke har noen mellomrom. Du skal fylle inn mottakers Bruker-ID (som mottaker finner i Min Profil).</strong></p>
      </div>
      <BottomBar />
    </div>
  );
};

export default ChangeOwner;
