import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import './MyPurchases.css';
import Navbar from '../../components/common/navbar/Navbar';
import BottomBar from '../../components/common/navbar/bottombar';

const MyPurchases = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchPurchases = async () => {
      if (user) {
        const q = query(collection(db, "tickets"), where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const purchasesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPurchases(purchasesData);
        setLoading(false);
      }
    };

    fetchPurchases();
  }, [user]);

  // Group purchases by date
  const groupedPurchases = purchases.reduce((acc, purchase) => {
    const date = new Date(purchase.purchaseDate).toLocaleDateString("no-NO");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(purchase);
    return acc;
  }, {});



  return (
    <div className="my-purchases-page">
        <Navbar />
    <div className="purchase-container">
      {loading && <p>Laster inn kjøp...</p>}
      <h1>Mine Kjøp</h1>
      {Object.keys(groupedPurchases).length > 0 ? (
        Object.entries(groupedPurchases).map(([date, purchases]) => (
          <div key={date} className="purchase-date-group">
            <h2 className="purchase-date">{date}</h2>
            <div className="purchases-list">
              {purchases.map((purchase) => (
                <div key={purchase.id} className="purchase-card">
                  <h3>Kamp: {purchase.match}</h3>
                  <p>Billett-type: <strong>{purchase.type === 'adult' ? 'Voksenbillett' : 'Barnebillett'}</strong></p>
                  <p>Felt: <strong>{purchase.section}</strong></p>
                  <p>Kampdato: <strong>{new Date(purchase.matchDate).toLocaleDateString()}</strong></p>
                  <p>Kjøpsdato: <strong>{new Date(purchase.purchaseDate).toLocaleDateString()}</strong></p>
                  <p>Billett ID: <strong>{purchase.ticketId}</strong></p>
                    

                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div><p>Ingen kjøp funnet</p></div>
      )}
      
    </div>
        <BottomBar />
    </div>

  );
};

export default MyPurchases;
