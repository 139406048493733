// BottomBar.js
import React from 'react';
import { FiHome, FiShoppingBag } from 'react-icons/fi';
import './bottomBar.css';
import { Link } from 'react-router-dom';

const BottomBar = () =>{
  return (
    <div className="bottom-bar">
      <Link to="/dashboard"><FiHome className="icon" /></Link>
      <Link to="/purchase"><FiShoppingBag className="icon" /></Link>

    </div>
  );
};

export default BottomBar;
