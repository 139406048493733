// Map.js
import React from 'react';
import './map.css'; // Assume you have a CSS file for styling this page specifically
import Navbar from '../../components/common/navbar/Navbar';
import BottomBar from '../../components/common/navbar/bottombar';
import { useNavigate } from 'react-router-dom';

const Map = () => {
  const navigate = useNavigate();

  return (
    <div className="map-page">
      <Navbar />
      <div className="map-container">
        {/* Assuming you have an image to use as a map. Adjust the source as needed. */}
        <img
          src="https://i.ibb.co/TYhHBXC/4342f5c7-3f92-4dc8-b919-42ce466da0f3.jpg"
          alt="Venue Map"
          className="venue-map"
        />
        {/* Button to navigate back to the dashboard */}
        <button onClick={() => navigate('/dashboard')} className="back-to-dashboard-btn">
          Tilbake
        </button>
      </div>
      <BottomBar />
    </div>
  );
};

export default Map;
