import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import './loginPage.css'; // Gjenbruker CSS fra Login for konsistens
import logo from './bil.png'; // Importerer logoen

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth();
  let navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passordene stemmer ikke overens.');
      return;
    }
    
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/login'); // Sender brukeren til login siden for å logge inn etter registrering
    } catch (err) {
      setError('Feil ved oppretting av konto: ' + err.message);
    }
  };

  return (
    <div className="auth-page">
              <meta name="theme-color" content="#fff" />
            <img src={logo} alt="Logo" className="login-logo" />
      <div className="auth-container">
        <h1 className="auth-header">Registrer deg</h1>
        {error && <p className="auth-error">{error}</p>}
        <form onSubmit={handleRegister} className="auth-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-post"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Passord"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Gjenta passord"
            required
          />
          <button type="submit">Registrer</button>
          <p className='privacy-policy'>Ved å registrere deg, er du innforstått med <a href='https://espenhalsen.github.io'>Personvernserklæringen for MittBIL</a></p>
        </form>
        <br />
        <Link to="/login" className="action-link">Allerede en bruker? Logg inn</Link>
        <Link to="/loginpage" className="action-link">Gå tilbake</Link> 
      </div>
    </div>
  );
};

export default Register;
