// Navbar.js
import React, { useState } from 'react';
import './navbar.css';
import logo from './bil.png';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import SlideMenu from './slide/SlideMenu'; // Importer SlideMenu-komponenten

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="navbar">
        <meta name="theme-color" content="#fff" />
        <div className="navbar-content">
          <Link to="/dashboard">
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Link>
          <FiMenu className="menu-icon" onClick={toggleMenu} />
        </div>
      </nav>
      <SlideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

export default Navbar;
