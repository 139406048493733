import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import './loginPage.css';
import logo from './bil.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth();
  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // eller hvor du vil navigere etter vellykket innlogging
    } catch (err) {
      setError('Feil ved innlogging: ' + err.message);
    }
  };

  return (
    <div className="auth-page">
              <img src={logo} alt="Logo" className="login-logo" />
      <div className="auth-container">
        <h1 className="auth-header">Logg Inn</h1>
        {error && <p className="auth-error">{error}</p>}
        <form onSubmit={handleLogin} className="auth-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-post"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Passord"
            required
          />
          <button type="submit">Logg Inn</button>
        </form>
        <br />
        <Link to="/forgot-password" className="action-link">Glemt passord?</Link>
        <Link to="/loginpage" className="action-link">Gå tilbake</Link>

      </div>
    </div>
  );
};

export default Login;
