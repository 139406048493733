import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './homePage.css';
import logo from './bil.png';

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/loginpage');
    }, 3000); // Wait for 3 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="homepage">
      <div className="splashscreen">
        <meta name="theme-color" content="#075198" />
        <img src={logo} alt="Logo" className="logo" />
        <div className="loading-circle"></div>

      </div>
    </div>
  );
};

export default HomePage;
