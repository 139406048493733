// Dashboard.js
import React, { useState, useEffect } from 'react';
import { getAuth,  onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './dashboard.css';
import Navbar from '../../components/common/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import QRCode from 'qrcode.react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import BottomBar from '../../components/common/navbar/bottombar';



const Dashboard = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userTickets, setUserTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [loading, setLoading] = useState(true); // Benytter loading-tilstanden
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Her endrer vi slik at kun delen av e-postadressen før "@" vises
        const emailPrefix = user.email.split('@')[0];
        setUserEmail(emailPrefix);
        fetchTickets(user.uid);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate, auth]);

  const fetchTickets = async (userId) => {
    setLoading(true); // Starter lastingen
    const ticketsRef = collection(db, "tickets");
    const q = query(ticketsRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const tickets = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setUserTickets(tickets);
    setLoading(false); // Avslutter lastingen
  };

  const toggleTicketDetails = (ticketId) => {
    setSelectedTicketId(selectedTicketId === ticketId ? null : ticketId);
  };

  return (
    <div className="dashboard">
      <Navbar />
      <div className="welcome">
      <div className="welcome-message">
        <h1>Hei igjen, {userEmail}!</h1>
        <p>Her finner du dine eksisterende billetter</p>
        
      </div>
     <div className="containerr-section"> 
      <div className="section">
        <h2>Mine Billetter:</h2>
        {loading ? (
          <div>Laster billetter...</div>
        ) : userTickets.length > 0 ? (
          userTickets.map((ticket) => (
            <div key={ticket.id} className="card"
                 onClick={() => toggleTicketDetails(ticket.id)}>
              <div className="card-content">
                <h3>{ticket.match}</h3>
                <p>Felt: {ticket.section === 'A' ? 'Tribune' : 'Tilrettelagt'}<br /> Dato: 24.08.2024<br />Type: {ticket.type === 'adult' ? 'Voksenbillett' : 'Barnebillett'}</p>
                {selectedTicketId === ticket.id ? <FiChevronUp /> : <FiChevronDown />}
              </div>
              {selectedTicketId === ticket.id && (
                <div className="qr-code">

                  <QRCode value={ticket.ticketId} size={128} level="H" includeMargin />
                  <p onClick={() => navigate(`/changeOwner/${ticket.ticketId}`)} style={{cursor: 'pointer', textDecoration: 'underline'}}>Trykk her for å endre Billetteier</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <div><p>Her var det tomt...</p></div>
        )}
                      <div className="welcome-message">
<br></br>
        <p>Har du problemer med appen?
          <br />Da kan du sende en epost til <a href=' mailto:post.bilfotball@gmail.com?subject=Feil%20ved%20kj%C3%B8p%20(via%20app%2Fnettside) '>post.bilfotball@gmail.com</a></p>
      </div>
      </div>
<br></br>
      <div className="buy-tickets">
        <h2>Ønsker du å kjøpe nye billetter?</h2>
        <button onClick={() => navigate('/purchase')}>Kjøp Billetter</button>

      </div>

    </div>
    
          </div>
          <p className="login-description1">
          Utviklet av Espen Halsen
        </p>
      <BottomBar />
    </div>
  );
};

export default Dashboard;
