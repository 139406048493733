import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import './loginPage.css'; // Gjenbruker CSS fra Login og Register for konsistens
import { Link } from 'react-router-dom'; // Importert for navigasjon
import logo from './bil.png'; // Importerer logoen

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(''); // Legg til en tilstand for feilmeldinger
  const auth = getAuth();

  const handleResetPassword = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage('En e-post for tilbakestilling av passordet har blitt sendt.');
      })
      .catch((error) => {
        setError('Feil: ' + error.message);
      });
  };

  return (
    <div className="auth-page">
                <meta name="theme-color" content="#fff" />
                    <img src={logo} alt="Logo" className="login-logo" />
      <div className="auth-container">
        <h1 className="auth-header">Glemt Passord</h1>
        {message && <div className="auth-message">{message}</div>}
        {error && <div className="auth-error">{error}</div>}
        <form onSubmit={handleResetPassword} className="auth-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-post"
            required
            className="auth-form-input" // Endret til matchende klasse navn
          />
          <button type="submit" className="auth-form-button">Send Tilbakestillings-Epost</button>
          <br />
          <Link to="/login" className="action-link">Gå tilbake</Link>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
